import { useLocation } from '@reach/router';
import * as React from 'react';

import { NoSSR } from '@xing-com/crate-utils-no-ssr';

import { scrollMemoPaths } from './scroll-memo-paths';

export const useScrollMemo = (): void => {
  const location = useLocation();

  React.useLayoutEffect(() => {
    return () => {
      scrollMemoPaths[location.pathname] = window.scrollY;
    };
  });
};

export const ScrollMemoInner: React.FC = () => {
  useScrollMemo();

  return null;
};

export const ScrollMemo: React.FC = () => {
  return (
    <NoSSR>
      <ScrollMemoInner />
    </NoSSR>
  );
};
