import { useLocation } from '@reach/router';
import * as React from 'react';

import { scrollMemoPaths } from './scroll-memo-paths';

const useMemoizedPos = (): ((path: string) => number) => {
  return (path: string): number => {
    return scrollMemoPaths?.[path] ?? 0;
  };
};

export const ScrollManager: React.FC = () => {
  const [previousPath, setPreviousPath] = React.useState<string>('');

  const location = useLocation();
  const getMemoizedPos = useMemoizedPos();

  React.useEffect(() => {
    if (location.pathname !== previousPath) {
      setPreviousPath(location.pathname);
      const pos = getMemoizedPos(location.pathname);
      window.scrollTo(0, pos || 0);
    }
  }, [location, getMemoizedPos, previousPath, setPreviousPath]);

  return null;
};
